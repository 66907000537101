import httpUtil from "@/utils/httpUtil";

/**优惠券列表 */
export const crmPcPageList = async params => httpUtil.post("/api/crmPc/company/coupon/crmPcPageList", params);

/**发布状态 */
export const updState = async params => httpUtil.post("/api/crmPc/company/coupon/updState", params);

/**商品分类 */
export const getGoodsTypeInfo = async params => httpUtil.post("/api/crmPc/companyGoods/getGoodsTypeInfo", params);

/**
 * 修改商品排序
 * @param params
 * @returns {Promise<*>}
 */
export const updateGoodsSort = async params => httpUtil.post("/api/crmPc/companyGoods/updateGoodsSort", params);

/**
 * 修改分类排序
 * @param params
 * @returns {Promise<*>}
 */
export const updateGoodsTypeSort = async params => httpUtil.post("/api/crmPc/companyGoods/updateGoodsTypeSort", params);


/**商品分类列表 */
export const selectGoodsTypeInfoPC = async params => httpUtil.post("/api/crmPc/companyGoods/selectGoodsTypeInfoPC", params);


/**商品分类新增 */
export const addGoodsType = async params => httpUtil.post("/api/crmPc/companyGoods/addGoodsType", params);


//删除分类
export const delGoodsType = async params => httpUtil.post("/api/crmPc/companyGoods/delGoodsType", params);

//删除分类
export const updateOnline = async params => httpUtil.post("/api/crmPc/companyGoods/updateOnline", params);


//商品列表
export const getGoodsInfo = async params => httpUtil.post("/api/crmPc/companyGoods/getGoodsInfoPC", params);

//编辑分类
export const selectGoodsType = async params => httpUtil.post("/api/crmPc/companyGoods/selectGoodsTypeInfoPC", params);


//删除商品
export const delGoods = async params => httpUtil.post("/api/crmPc/companyGoods/delGoodsInfo", params);

//x新增商品
export const addGoods = async params => httpUtil.post("/api/crmPc/companyGoods/addGoodsInfoList", params);


/**发布优惠券 */
export const add = async params => httpUtil.post("/api/crmPc/company/coupon/add", params);

/**删除优惠券 */
export const del = async params => httpUtil.post("/api/crmPc/company/coupon/del", params);

/**编辑优惠券 */
export const upd = async params => httpUtil.post("/api/crmPc/company/coupon/upd", params);


/**优惠券领取记录 */
export const coupponRecords = async params => httpUtil.post("/api/crmPc/company/coupon/coupponRecords", params);

/** 查询企业竞价权限 */
export const getAuctionSetting = async params => httpUtil.post("/api/crmPc/companyGoods/getAuctionSetting", params);
